import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import lotties from 'lottie-web';

import appRoutes from './app';
import permissionsRoute from './permissions';
import unsubscribeRoutes from './unsubscribe';

Vue.use(VueRouter);

function hasPermission(to, plan) {
  // Array to verify the validations in the end
  const validations = [];
  console.debug('PLAN TEST: ', plan);
  if (plan) {
    if (to.path.indexOf('/live') !== -1 || to.path.indexOf('/departments') !== -1) {
      console.debug('Checking permissions...');
      // Push the result of the permission check to the validations array
      validations.push(permissionsRoute.checkPlanPermission(plan, to.path));
      // return permissionsRoute.checkPlanPermission(plan, to.path);
    }
  }

  console.debug('ROLE TEST: ', store.state.auth.user.role, '\nto: ', to.path);
  // Check role permission
  validations.push(permissionsRoute.checkRole(store.state.auth.user.role, to.path));

  // if (to.meta.role) {
  //   return to.meta.role.split(',').indexOf(store.state.auth.user.role) !== -1;
  // }
  console.debug('Validations: ', validations);
  // Verify if all validations are true
  for (let i = 0; i < validations.length; i += 1) {
    if (!validations[i]) {
      return false;
    }
  }

  return true;
}

function createLoader() {
  const load = document.createElement('div');
  const lottieWrapper = document.createElement('div');

  lottieWrapper.setAttribute('id', 'lottie_loader');

  let path;

  if (window.location.hostname !== 'app.yup.chat' || window.location.hostname !== 'dev.yup.chat') { // Remove loading gif if is whitelabel domain.
    path = null;
  } else {
    path = 'assets/img/page_loading.json';
  }

  if (window.$rebranding.active) {
    document.body.appendChild(lottieWrapper);
    lotties.loadAnimation({
      container: document.getElementById('lottie_loader'), // the dom element that will contain the animation.
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path, // the path to the animation json defined above.
    });
  } else {
    load.className = 'qt-block-ui';
    document.body.appendChild(load);
  }

  return () => {
    setTimeout(() => {
      load.remove();
      lottieWrapper.remove();
    }, 100);
  };
}

let routes;
let isUnsubscribe;

switch (window.location.hostname) {
  case 'unsubscribe.yupchat.net':
  case '127.0.0.1':
    routes = unsubscribeRoutes;
    isUnsubscribe = true;
    break;
  default:
    routes = appRoutes;
    isUnsubscribe = false;
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // const publicPages = ['/login', '/register', '/reset-password', '/reset-verification/:token'];
  const authRequired = !to.meta.guest && !to.meta.both;
  const bothAccess = to.meta.both;
  const loggedIn = localStorage.getItem('yup_chat_token');
  // const tfa = localStorage.getItem('yup_chat_tfa');

  if (to.meta.title) document.title = to.meta.title;

  if (isUnsubscribe) {
    next();
  } else if (authRequired) {
    if (!loggedIn) {
      next('/login');
    // } else if (tfa !== loggedIn && to.path !== '/2fa' && to.path !== '/login') {
    //   next('/2fa');
    } else {
      const removeLoader = createLoader();

      store.dispatch('auth/refresh').then((foo) => {
        store.dispatch('account/info').then((info) => {
          store.dispatch('fetchOmniPlan');

          if (hasPermission(to, store.state.plans.omniPlan)) {
            next();
          } else {
            next({
              name: 'Forbidden',
              params: [to.path],
              replace: true,
            });
          }

          removeLoader();
        });
      }, () => {
        removeLoader();
        next('/login');
      });
    }
  } else if (!loggedIn || bothAccess) {
    next();
  } else {
    next('');
  }
});

export default router;
