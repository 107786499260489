<template>
  <!--START PAGE HEADER -->
  <header class="page-header">
    <!-- <pre>{{title}}, {{subtitle}}, {{subtitle3rd}}, {{subtitle4th}}, {{breadcrumbLinks}}, {{accName}}</pre> -->
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <h1 class="text-capitalize">
          <!-- <code>{{this.breadcrumbLinks}}</code> -->
          <!-- <code>{{Object.keys(this.breadcrumbLinks).length}}</code> -->
          <router-link v-if="this.breadcrumbLinks" :to="this.breadcrumbLinks[1]"><span class="title">{{ title
              }}</span></router-link>
          <span v-else class="title" :class="currentUrl === '/' ? 'title-home' : ''">{{ title }}</span><span
            class="acc-title" v-if="this.accName">{{ accName }}</span>
          <!-- <span>&#x2022;</span> -->
          <template v-if="this.breadcrumbLinks">
            <template
              v-if="Object.keys(this.breadcrumbLinks).length === 2 || Object.keys(this.breadcrumbLinks).length > 2">
              <span
                v-if="Object.keys(this.breadcrumbLinks).length === 2 || Object.keys(this.breadcrumbLinks).length > 2"
                class="material-symbols-outlined">chevron_right</span>
              <router-link :class="[subtitle === '' ? 'animated-background dummy-scale' : '']"
                v-if="this.breadcrumbLinks" :to="this.breadcrumbLinks[2]"><span class="subtitle"
                  :class="[subtitle && !subtitle3rd ? 'active' : '']">{{ subtitle }}</span></router-link>
              <span v-else class="subtitle" :class="[subtitle && !subtitle3rd ? 'active' : '']">{{ subtitle }}</span>
            </template>
            <template
              v-if="Object.keys(this.breadcrumbLinks).length === 3 || Object.keys(this.breadcrumbLinks).length > 3">
              <span
                v-if="Object.keys(this.breadcrumbLinks).length === 3 || Object.keys(this.breadcrumbLinks).length > 3"
                class="material-symbols-outlined">chevron_right</span>
              <router-link :class="[subtitle3rd === '' ? 'animated-background dummy-scale' : '']"
                v-if="this.breadcrumbLinks" :to="this.breadcrumbLinks[3]"><span class="subtitle"
                  :class="[subtitle && subtitle3rd ? 'active' : '']">{{ subtitle3rd }}</span></router-link>
              <span v-else class="subtitle" :class="[subtitle && subtitle3rd ? 'active' : '']">{{ subtitle3rd }}</span>
            </template>
            <template
              v-if="Object.keys(this.breadcrumbLinks).length === 4 || Object.keys(this.breadcrumbLinks).length > 4">
              <span
                v-if="Object.keys(this.breadcrumbLinks).length === 4 || Object.keys(this.breadcrumbLinks).length > 4"
                class="material-symbols-outlined">chevron_right</span>
              <router-link :class="[subtitle4th === '' ? 'animated-background dummy-scale' : '']"
                v-if="this.breadcrumbLinks" :to="this.breadcrumbLinks[4]"><span class="subtitle"
                  :class="[subtitle && subtitle4th ? 'active' : '']">{{ subtitle4th }}</span></router-link>
              <span v-else class="subtitle" :class="[subtitle && subtitle4th ? 'active' : '']">{{ subtitle4th }}</span>
            </template>
          </template>
          <template v-else-if="subtitle">
            <span class="material-symbols-outlined">chevron_right</span>
            <span class="subtitle active">{{ subtitle }}</span>
          </template>
        </h1>
      </div>
      <ul class="actions top-right">
        <slot name="actions" />
      </ul>
    </div>
    <slot />
  </header>
  <!--END PAGE HEADER -->
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['title', 'subtitle', 'subtitle3rd', 'subtitle4th', 'breadcrumbLinks', 'accName'],
  /* props: {
    title: {
      type: Object,
      default: () => ({}),
    },
    subtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitle3rd: {
      type: Object,
      default: () => ({}),
    },
    subtitle4th: {
      type: Object,
      default: () => ({}),
    },
    breadcrumbLinks: {
      type: Object,
      default: () => ({}),
    },
    accName: {
      type: String,
    },
  }, */
  mounted() {
    console.log('PageHeader:mounted');
    console.log('Current URL: ', window.location.pathname);
    if (!window.originalTitle) window.originalTitle = document.title;
    document.title = `${window.originalTitle} - ${this.title}`;
  },
  destroyed() {
    if (window.originalTitle) document.title = window.originalTitle;
  },
  data() {
    return {
      currentUrl: window.location.pathname,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-header {
  @media (max-width: 992px) {
    h1 {
      font-size: 1rem;
      height: auto !important;
    }
  }
}

.page-header {
  padding-bottom: 2rem;

  h1 {
    display: flex;
    align-items: center;
    gap: .75rem;
    font-size: inherit;
  }

  span {
    font-size: 1rem;
  }

  span.title-home,
  .acc-title {
    font-size: 2rem;
    font-weight: 400;
    color: var(--foreground);
  }

  span.material-symbols-outlined {
    font-weight: bold;
    color: var(--gray-font-color-4);
    font-size: 24px;
    transform: scale(.8);
  }

  .acc-title {
    font-weight: bold;
  }

  .actions.top-right {
    right: 30px;
    top: 30px;
    bottom: 0;
  }
}

span.title,
span.subtitle {
  color: var(--gray-font-color-4);
}

span.subtitle.active {
  color: var(--clr-yup-purple) !important;
}

.dummy-scale {
  width: 50px;
  height: 22px;
}

.animated-background {
  animation-duration: 1.5s;
}
</style>
