export default {
  state: {
    whitelabel: {},
    isWhitelabelLoading: false,
  },
  mutations: {
    fetchWhitelabel(state, whitelabel) {
      state.whitelabel = whitelabel;
    },
    setWhitelabelLoading(state, isLoading) {
      state.isWhitelabelLoading = isLoading;
    },
  },
  actions: {
    fetchAdminPlans({ commit, state }, query) {
      return this._vm.$api.admin.plans.fetchPlans(query);
    },
    fetchAdminProducts({ commit, state }, query) {
      return this._vm.$api.admin.products.fetch(query);
    },
    updateAdminPlans({ commit, state }, payload) {
      return this._vm.$api.admin.plans.update(payload.planId, payload.input);
    },
    fetchAdminPlan({ commit, state }, planId) {
      return this._vm.$api.admin.plans.show(planId);
    },
    fetchAdminInvoices({ commit, state }, query) {
      return this._vm.$api.admin.invoices.fetchInvoices(query);
    },
    fetchCustomerInvoices({ commit, state }, accountId, query) {
      return this._vm.$api.admin.customers.fetchInvoices(accountId, query);
    },
    fetchCustomerPlan({ commit, state }, payload) {
      return this._vm.$api.admin.customers.fetchCustomerPlan(payload.accountId, payload.planId);
    },
    storeAdminPlans({ commit, state }, input) {
      return this._vm.$api.admin.plans.store(input);
    },
    destroyAdminPlan({ commit, state }, planId) {
      return this._vm.$api.admin.plans.destroy(planId);
    },
    attachPlan({ commit, state }, payload) {
      return this._vm.$api.admin.customers.attachPlan(payload.customerId, payload.input);
    },
    detachPlan({ commit, state }, payload) {
      return this._vm.$api.admin.customers.detachPlan(payload.account_id, payload.plan_id);
    },
    searchCompany({ commit, state }, query) {
      return this._vm.$api.admin.customers.searchCompany(query);
    },
    fetchAdminCustomers({ commit, state }, query) {
      return this._vm.$api.admin.customers.fetch(query);
    },
    updateCustomer({ commit, state }, payload) {
      return this._vm.$api.admin.customers.updateCustomer(payload.customerId, payload.input);
    },
    destroyCustomer({ commit, state }, customerId) {
      return this._vm.$api.admin.customers.destroy(customerId);
    },
    fetchAdminSmsMessages({ commit, state }, query) {
      return this._vm.$api.admin.sms.messages.fetch(query);
    },
    fetchAdminRcsInsights({ commit, state }, query) {
      return this._vm.$api.admin.rcs.messages.insights(query);
    },
    fetchAdminRcsMessages({ commit, state }, query) {
      return this._vm.$api.admin.rcs.messages.fetch(query);
    },
    fetchAdminRcsSintetic({ commit, state }, query) {
      return this._vm.$api.admin.rcs.messages.sintetic(query);
    },
    fetchWhitelabel({ commit, state }, query) {
      // commit('setWhitelabelLoading', true);
      return this._vm.$api.admin.whitelabel.fetch(query)
        .then((whitelabel) => {
          commit('fetchWhitelabel', whitelabel);
          commit('setWhitelabelLoading', true);
          return whitelabel;
        });
    },
    updateWhitelabel({ commit, state }, payload) {
      return this._vm.$api.admin.whitelabel.update(payload);
    },
    fetchTestResults({ commit, state }, query) {
      return this._vm.$api.admin.sms.monitor.fetch(query);
    },
    fetchMonitorAnalytics({ commit, state }, query) {
      return this._vm.$api.admin.sms.monitor.fetchAnalytics(query);
    },
  },
};
